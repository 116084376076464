<template>
    <el-dialog append-to-body :title="expiredTitle"
               :visible.sync="loginExpiredVisible"
               :show-close="false"
               :close-on-click-modal="false"
               :close-on-press-escape="false"
               width="40%"
               center>
        <div class="cont-wrap">
            <span>抱歉，您的登录凭证已过期，请跳转至系统首页重新登录！</span>
        </div>
        <div slot="footer" class="cont-foot">
            <!--<el-button type="warning" size="medium" @click="handleToClose">关闭系统-->
            <!--</el-button>-->
            <el-button type="primary" @click="handleToLogin">我知道了
            </el-button>
        </div>
    </el-dialog>
</template>

<script>
    import store from '@/store/index';

    export default {
        name: "LoginExpired",
        data() {
            return {
                expiredTitle: '登录凭证已过期'
            };
        },
        created() {
        },
        computed: {
            loginExpiredVisible: () => {
                const loginExpired = store.state.loginExpired;
                // console.log("LoginExpired:", loginExpired);
                return loginExpired;
            }
        },
        methods: {
            handleToLogin() {
                this.$router.replace("/");
            },
            handleToClose() {
                window.close();
            }
        }
    };
</script>

<style lang="scss" scoped>
    .cont-wrap {
        padding: 20px 50px 50px 50px;
    }

    .cont-foot {
        padding-bottom: 30px;
    }
</style>
