import Layout from "@/entry/pdt/Layout.vue";

// 开票管理
const salMenus = {
  name: "Sal",
  path: "/open",
  // path: "/sal",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "RB",
    title: "开票管理",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/open.png"),
    icon: "el-icon-tickets",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "SalFpkj",
      path: "issue",
      // path: "fpkj",
      hidden: false,
      meta: {
        code: "RB",
        title: "发票开具",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-open/invoice-issue/InvoiceIssue",
    },
    //   {
    //     name: "SalFptk",
    //     path: "fptk",
    //     hidden: false,
    //     meta: {
    //       code: "RB",
    //       title: "手工开票",
    //       menu: false,
    //       keepAlive: true,
    //       icon: null,
    //       link: null,
    //     },
    //     component: "invoice-open/invoice-issue/retail/RetailManualInvoice",
    //   },
    //   {
    //     name: "SalKpjl",
    //     path: "kpjl",
    //     hidden: false,
    //     meta: {
    //       code: "RB",
    //       title: "开票记录",
    //       menu: false,
    //       keepAlive: true,
    //       icon: null,
    //       link: null,
    //     },
    //     component: "invoice-open/invoice-issue/retail/RetailAlreadyInvoice",
    //   },
    //   {
    //     name: "SalDkfp",
    //     path: "dkfp",
    //     hidden: false,
    //     meta: {
    //       code: "RB",
    //       title: "待开发票",
    //       menu: false,
    //       keepAlive: true,
    //       icon: null,
    //       link: null,
    //     },
    //     component: "invoice-open/invoice-issue/retail/RetailWaitInvoice",
    //   },
    {
      name: "SalSmkp",
      path: "quick-invoice",
      // path: "smkp",
      hidden: false,
      meta: {
        code: "RB-M11",
        title: "扫码开票",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-open/quick-invoice/index",
    },
    {
      // 次要
      name: "SalHpkj",
      path: "red-invoice",
      // path: "hpkj",
      hidden: false,
      meta: {
        code: "RB-M10",
        title: "红票开具",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component:
        "invoice-open/invoice-red/red-invoice-open/BillingRedInvoiceIssue",
    },
    {
      name: "SalKpcy",
      path: "differenceDetail",
      hidden: true,
      meta: {
        code: "RB",
        title: "开票差异",
        keepAlive: true,
        menu: true,
        icon: null,
        link: null,
      },
      component: "invoice-open/invoice-difference/DifferenceDetail",
    },
    {
      name: "SalFpyj",
      path: "express",
      // path: "fpyj",
      hidden: false,
      meta: {
        code: "RB-M02",
        title: "发票邮寄",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-open/invoice-express/InvoiceExpressList",
    },
    {
      name: "SalKpsz",
      path: "billing-config",
      // path: "kpsz",
      hidden: false,
      meta: {
        code: "RB-M03",
        title: "开票设置",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "output/invoiceOpenConfiguration",
    },
    {
      name: "SalHzxxb",
      path: "red-notice/form",
      // path: "hzxxb",
      hidden: true,
      meta: {
        code: "RB",
        title: "红字信息表",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-open/invoice-red/BillingRedNoticeForm",
    },
    {
      name: "SalSdzh",
      path: "sdzh",
      // path: "sdzh",
      hidden: false,
      meta: {
        code: "RB-M04",
        title: "数电账户",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "rpa/e-account/v1/index",
    },
    {
      // 无用
      name: "SalKptj",
      path: "billing-statistics",
      // path: "kptj",
      hidden: false,
      meta: {
        code: "BS-001",
        title: "开票统计",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-open/billing-statistics/index",
    },
    {
      name: "SalDdgl",
      path: "invoicing-trade",
      // path: "ddgl",
      hidden: false,
      meta: {
        code: "om-001",
        title: "订单管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "sal/invoicing-trade/index",
    },
    {
      path: "issue/red-notice/form",
      name: "SalHzfp",
      component: "declare/tmpl/History",
      hidden: true,
      meta: {
        code: "RB",
        title: "红字发票",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
    },
    {
      name: "SalHztzd",
      path: "red-notice",
      // path: "hztzd",
      hidden: false,
      meta: {
        code: "RB",
        title: "红字通知",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-open/invoice-red/BillingRedNotice",
    },
  ],
};

// 收票中心
const colMenus = {
  name: "Col",
  path: "/collect",
  //   path: "/col",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "RC",
    title: "收票中心",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/center.png"),
    icon: "el-icon-receiving",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "ColZzsp",
      path: "list",
      //   path: "zzsp",
      hidden: false,
      meta: {
        code: "RC",
        title: "增值税票",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-center/InvoiceCenterList",
    },
    {
      name: "ColSmsp",
      path: "list/scanning/gun",
      hidden: true,
      component: "invoice-center/ScanningGunCollectInvoice",
      meta: {
        code: "RC-F01",
        title: "扫码收票",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
    },
    {
      name: "ColScsp",
      path: "list/scanner",
      hidden: true,
      component: "invoice-center/ScannerCollectInvoice",
      meta: {
        code: "RC-F02",
        title: "扫描收票",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
    },
    {
      name: "ColUpsp",
      path: "list/upload",
      hidden: true,
      component: "invoice-center/LocalCollectInvoice",
      meta: {
        code: "RC-F03",
        title: "本地收票",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
    },
    {
      name: "ColQtfp",
      path: "other/invoice",
      //   path: "qtfp",
      hidden: false,
      meta: {
        code: "RC",
        title: "其它发票",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-center/OtherInvoice",
    },
    {
      name: "ColSbjl",
      path: "recognize/failure/record",
      //   path: "sbjl",
      hidden: false,
      meta: {
        code: "RC",
        title: "识别记录",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-center/RecognizeFailureRecord",
    },
    {
      name: "ColSpsz",
      path: "recognize/invoice/setting",
      //   path: "spsz",
      hidden: false,
      meta: {
        code: "IS",
        title: "收票设置",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-center/InvoiceSetting",
    },
    {
      name: "ColYxgl",
      // path: "/itr/mail",
      path: "yxgl",
      hidden: false,
      meta: {
        code: "RS-YXSZ",
        title: "邮箱管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "itr/mail",
    },
  ],
};

// 我的票池
const invMenus = {
  name: "Inv",
  path: "/pool",
  //   path: "/inv",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "RI",
    title: "我的票池",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/pool.png"),
    icon: "el-icon-wallet",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "InvJxfp",
      path: "income",
      //   path: "jxfp",
      hidden: false,
      meta: {
        code: "RI-M01",
        title: "进项发票",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-pool/IncomeInvoicePool",
    },
    {
      name: "InvXxfp",
      path: "sales",
      //   path: "xxfp",
      hidden: false,
      meta: {
        code: "RI-M02",
        title: "销项发票",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "invoice-pool/SalesInvoicePool",
    },
  ],
};

// 凭证管理 VM
const vouMenus = {
  name: "Vou",
  path: "/voucher",
  //   path: "/vou",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "VM",
    title: "凭证管理",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/open.png"),
    icon: "el-icon-edit-outline",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "VouPzlb",
      path: "list",
      //   path: "pzlb",
      hidden: false,
      meta: {
        code: "VM-LS",
        title: "凭证列表",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "voucher-manage/list",
    },
    {
      name: "VouPzmb",
      path: "template",
      //   path: "pzmb",
      hidden: false,
      meta: {
        code: "VM-TP",
        title: "凭证模板",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "voucher-manage/template",
    },
    {
      name: "VouKmgl",
      path: "subject",
      //   path: "kmgl",
      hidden: false,
      meta: {
        code: "VM-KM",
        title: "科目管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "voucher-manage/subject",
    },
    {
      name: "VouXmgl",
      path: "items",
      //   path: "xmgl",
      hidden: false,
      meta: {
        code: "VM-XM",
        title: "项目管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "voucher-manage/items",
    },
    {
      name: "VouPzsz",
      path: "config",
      //   path: "pzsz",
      hidden: false,
      meta: {
        code: "VM-VC",
        title: "凭证设置",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "voucher-manage/config",
    },
  ],
};

// 发票认证
const chkMenus = {
  name: "Chk",
  path: "/deduction",
  //   path: "/chk",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "RD",
    title: "抵扣认证",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/deduction.png"),
    icon: "el-icon-finished",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "ChkRzzt",
      path: "certification",
      //   path: "rzzt",
      hidden: false,
      meta: {
        code: "RD-M04",
        title: "认证主体",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "income-selection/Certification",
    },
    {
      name: "ChkFpgx",
      path: "selection",
      //   path: "fpgx",
      hidden: false,
      meta: {
        code: "RD-M04",
        title: "发票勾选",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "income-selection/Selection",
    },
    {
      name: "ChkFprz",
      path: "incomeDeduction",
      //   path: "fprz",
      hidden: false,
      meta: {
        code: "RD-M04",
        title: "发票认证",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "income-deduction/IncomeDeduction",
    },
  ],
};

// 销售协同
const cooSalMenus = {
  name: "CooSal",
  path: "/coo/sal",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "RO",
    title: "销售协同",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/supplier.png"),
    icon: "el-icon-share",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "CooTrade",
      path: "trade",
      hidden: false,
      meta: {
        code: "RO-M07",
        title: "结算开票",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "SS",
      },
      props: { cooRole: "SS" },
      component: "cooperate/settlement/SettlementTradeList",
    },
    {
      name: "CooBuyer",
      path: "buyer/list",
      hidden: false,
      meta: {
        code: "RO-M05",
        title: "需方管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "SS",
      },
      props: { cooRole: "SS" },
      component: "cooperate/retailer/Retailer",
    },
    {
      name: "CooTradeDetail",
      path: "trade/detail",
      hidden: true,
      meta: {
        code: "RO-M07",
        title: "结算详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "SS",
      },
      props: { cooRole: "SS" },
      component: "cooperate/settlement/SettlementTradeDetail",
    },
    {
      name: "CooTradeInvoicingDetail",
      path: "trade/invoicing/detail",
      hidden: true,
      meta: {
        code: "RO-M07",
        title: "开票详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "SS",
      },
      props: { cooRole: "SS" },
      component: "cooperate/settlement/SettlementInvoicingDetail",
    },
    {
      path: "trade/receiving/detail",
      name: "CooTradeReceivingDetail",
      hidden: true,
      meta: {
        code: "RO-M07",
        title: "交票详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "SS",
      },
      props: { cooRole: "SS" },
      component: "cooperate/settlement/SettlementReceivingDetail",
    },
    {
      name: "CooTradeMatchingDetail",
      path: "trade/matching/detail",
      hidden: true,
      meta: {
        code: "RO-M07",
        title: "稽核详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "SS",
      },
      props: { cooRole: "SS" },
      component: "cooperate/settlement/SettlementMatchingDetail",
    },
    {
      name: "CooTradeEvidenceDetail",
      path: "trade/evidence/detail",
      hidden: true,
      meta: {
        code: "RO-M07",
        title: "打印预览",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "SS",
      },
      props: { cooRole: "SS" },
      component: "cooperate/settlement/SettlementEvidenceDetail",
    },
    {
      name: "CooTradeReceivingScanner",
      path: "trade/receiving/scanner",
      hidden: true,
      meta: {
        code: "RO-M07",
        title: "扫描上传",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "SS",
      },
      props: { cooRole: "SS" },
      component: "invoice-center/ScannerCollectInvoice",
    },
    {
      name: "CooTradeReceivingLocalup",
      path: "trade/receiving/localup",
      hidden: true,
      meta: {
        code: "RO-M07",
        title: "文件上传",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "SS",
      },
      props: { cooRole: "SS" },
      component: "invoice-center/LocalCollectInvoice",
    },
  ],
};

// 采购协同
const cooBuyMenus = {
  name: "CooBuy",
  path: "/coo/buy",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "RO",
    title: "采购协同",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/retailer.png"),
    icon: "el-icon-connection",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "CooTrade",
      path: "trade",
      hidden: false,
      meta: {
        code: "RO-M03",
        title: "结算收票",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "cooperate/settlement/SettlementTradeList",
    },
    {
      name: "CooSeller",
      path: "seller/list",
      hidden: false,
      meta: {
        code: "RO-M05",
        title: "供方管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "cooperate/seller-manage/SellerManage",
    },
    {
      name: "CooReceiveList",
      path: "receive/list",
      hidden: false,
      meta: {
        code: "RO-M05",
        title: "结算发票",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "cooperate/settlement/SettlementReceivingList",
    },
    {
      name: "CooTradeDetail",
      path: "trade/detail",
      hidden: true,
      meta: {
        code: "RO-M03",
        title: "结算详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "cooperate/settlement/SettlementTradeDetail",
    },
    {
      name: "CooTradeInvoicingDetail",
      path: "trade/invoicing/detail",
      hidden: true,
      meta: {
        code: "RO-M03",
        title: "开票详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "cooperate/settlement/SettlementInvoicingDetail",
    },
    {
      name: "CooTradeReceivingDetail",
      path: "trade/receiving/detail",
      hidden: true,
      meta: {
        code: "RO-M03",
        title: "交票详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "cooperate/settlement/SettlementReceivingDetail",
    },
    {
      name: "CooTradeMatchingDetail",
      path: "/coo/buy/trade/matching/detail",
      hidden: true,
      meta: {
        code: "RO-M03",
        title: "稽核详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "cooperate/settlement/SettlementMatchingDetail",
    },
    {
      name: "CooTradeEvidenceDetail",
      path: "trade/evidence/detail",
      hidden: true,
      meta: {
        code: "RO-M03",
        title: "打印预览",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "cooperate/settlement/SettlementEvidenceDetail",
    },
    {
      name: "CooTradeReceivingScanner",
      path: "trade/receiving/scanner",
      hidden: true,
      meta: {
        code: "RO-M03",
        title: "扫描上传",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "invoice-center/ScannerCollectInvoice",
    },
    {
      name: "CooTradeReceivingLocalup",
      path: "trade/receiving/localup",
      hidden: true,
      meta: {
        code: "RO-M03",
        title: "文件上传",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
        cooRole: "DS",
      },
      props: { cooRole: "DS" },
      component: "invoice-center/LocalCollectInvoice",
    },
  ],
};

// 入账管理
const etyMenus = {
  name: "Ety",
  path: "/entry",
  //   path: "/ety",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "RZ",
    title: "入账管理",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/reimbursement.png"),
    icon: "el-icon-collection-tag",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "EtyFprz",
      path: "list",
      //   path: "fprz",
      hidden: false,
      meta: {
        code: "RZ-VAT",
        title: "发票入账",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "entry/invoice-entry",
    },
    {
      name: "EtyRzsz",
      path: "set",
      //   path: "rzsz",
      hidden: false,
      meta: {
        code: "RZ-RZSZ",
        title: "入账设置",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "entry/List",
    },
  ],
};

// 纳税申报
const decMenus = {
  name: "Dec",
  path: "/declare",
  //   path: "/dec",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "DEC",
    title: "纳税申报",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/reimbursement.png"),
    icon: "el-icon-thumb",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "DecSbmb",
      path: "ent/tmpl/list",
      //   path: "sbmb",
      hidden: false,
      meta: {
        code: "DEC-TMPL-ENT-QUERY",
        title: "申报模板",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "declare/tmpl/index",
    },
    {
      name: "DecSbmbXq",
      path: "ent/tmpl/detail",
      //   path: "sbmb/xq",
      hidden: true,
      meta: {
        code: "DEC-TMPL-ENT-QUERY",
        title: "申报模板详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "declare/tmpl/Detail",
    },
    {
      name: "DecSbmbRz",
      path: "tmpl/detail/history",
      //   path: "sbmb/rz",
      hidden: true,
      meta: {
        code: "DEC-TMPL-ENT-QUERY",
        title: "底稿更新日志",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "declare/tmpl/History",
    },
    {
      name: "DecWbsj",
      path: "ent/external/list",
      //   path: "wbsj",
      hidden: false,
      meta: {
        code: "DEC-TMPL-ENT-QUERY",
        title: "外部数据",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "declare/external/index",
    },
    {
      name: "DecWbsjXq",
      path: "ent/external/detail",
      //   path: "wbsj/xq",
      hidden: true,
      meta: {
        code: "DEC-TMPL-ENT-QUERY",
        title: "外部数据详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "declare/external/detail",
    },
    {
      name: "DecSbsz",
      // path: "/declare/setting",
      path: "",
      hidden: false,
      meta: {
        code: "DEC-CONFIG",
        title: "申请设置",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      children: [
        {
          name: "DecSjmb",
          path: "data/ent/list",
          //   path: "sjmb",
          hidden: false,
          meta: {
            code: "DEC-DATA-ENT-QUERY",
            title: "数据模板",
            menu: true,
            keepAlive: true,
            icon: null,
            link: null,
          },
          component: "declare/data/Index",
        },
        {
          name: "DecSjmbXq",
          path: "data/ent/detail",
          //   path: "sjmb/xq",
          hidden: true,
          meta: {
            code: "DEC-DATA-ENT-QUERY",
            title: "数据模板详情",
            menu: false,
            keepAlive: true,
            icon: null,
            link: null,
          },
          component: "declare/data/Detail",
        },
        {
          name: "DecDgmb",
          path: "tmpl/tnt/list",
          //   path: "dgmb",
          hidden: false,
          meta: {
            code: "DEC-TEMP-TNT-QUERY",
            title: "底稿模板",
            menu: true,
            keepAlive: true,
            icon: null,
            link: null,
          },
          component: "declare/setting/DeclareTntDeclareTmpl",
        },
        {
          name: "DecKmzd",
          path: "dict",
          //   path: "kmzd",
          hidden: false,
          meta: {
            code: "DEC-DICT-QUERY",
            title: "科目字典",
            menu: true,
            keepAlive: true,
            icon: null,
            link: null,
          },
          component: "declare/setting/DeclareEntSubjectDict",
        },
        {
          name: "DecSbzt",
          path: "setting/list",
          //   path: "sbzt",
          hidden: false,
          meta: {
            code: "DEC-SETTING-QUERY",
            title: "申报主体",
            menu: true,
            keepAlive: true,
            icon: null,
            link: null,
          },
          component: "declare/setting/DeclareEntSetting",
        },
        {
          name: "DecSbztXq",
          path: "setting/detail",
          //   path: "sbzt/xq",
          hidden: true,
          meta: {
            code: "DEC-SETTING-QUERY",
            title: "申报主体详情",
            menu: false,
            keepAlive: true,
            icon: null,
            link: null,
          },
          component: "declare/setting/DeclareEntSettingDetail",
        },
      ],
    },
  ],
};

// 系统管理
const sysMenus = {
  name: "Sys",
  path: "/system",
  //   path: "/sys",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "RS",
    title: "系统管理",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/system.png"),
    icon: "el-icon-setting",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "SysUser",
      path: "user-manage",
      //   path: "user",
      hidden: false,
      meta: {
        code: "RS-M02",
        title: "用户管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/user",
    },
    {
      name: "SysRole",
      path: "role-manage",
      //   path: "role",
      hidden: false,
      meta: {
        code: "RS-M01",
        title: "岗位管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/RoleConfig",
    },
    {
      name: "SysDept",
      path: "organization",
      //   path: "dept",
      hidden: false,
      meta: {
        code: "RS-M03",
        title: "组织管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/organization/list",
    },
    {
      name: "SysDeptXz",
      path: "organization/create",
      //   path: "dept/xz",
      hidden: true,
      meta: {
        code: "RS-M03",
        title: "新增组织",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/organization/create",
    },
    {
      name: "SysDeptXq",
      path: "organization/details",
      //   path: "dept/xq",
      hidden: true,
      meta: {
        code: "RS-M03",
        title: "组织详情",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/organization/details",
    },
    {
      name: "SysDeptCg",
      path: "organization/success",
      //   path: "dept/cg",
      hidden: true,
      meta: {
        code: "RS-M03",
        title: "新增成功",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/organization/success",
    },
    {
      name: "SysTent",
      path: "organization/TenantDetails",
      //   path: "tent",
      hidden: true,
      meta: {
        code: "RS-M03",
        title: "单位信息",
        menu: false,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/organization/TenantDetails",
    },
    {
      name: "SysEqpt",
      path: "equipment",
      //   path: "eqpt",
      hidden: false,
      meta: {
        code: "RS-M04",
        title: "设备管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "equipment/List",
    },
    {
      name: "SysMdse",
      path: "merchandise",
      //   path: "mdse",
      hidden: false,
      meta: {
        code: "HOTEL#RS-M05",
        title: "商品管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/merchandise/List",
    },
    {
      name: "SysMdse",
      path: "merchandise",
      //   path: " mdse",
      hidden: false,
      meta: {
        code: "RETAIL#RS-M05",
        title: "商品管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/merchandise/List",
    },
    {
      name: "SysCtcs",
      path: "customer",
      //   path: "ctcs",
      hidden: false,
      meta: {
        code: "RS-M08",
        title: "往来单位",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/customer/List",
    },
    {
      name: "SysTags",
      path: "label",
      //   path: "tags",
      hidden: false,
      meta: {
        code: "RS-M12",
        title: "标签管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/label/Label",
    },
    {
      name: "SysMail",
      path: "mail",
      //   path: "mail",
      hidden: false,
      meta: {
        code: "RS-M09",
        title: "邮件模板",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/mail/List",
    },
    {
      name: "SysEvnt",
      path: "/system/event",
      //   path: "evnt",
      hidden: false,
      meta: {
        code: "RS-M10",
        title: "事件通知",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/event/List",
    },
    {
      name: "SysFjyx",
      path: "email",
      //   path: "fjyx",
      hidden: false,
      meta: {
        code: "RS-M06",
        title: "发件邮箱",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "system/email/Email",
    },
    {
      name: "SysKpfs",
      path: "paas/info",
      //   path: "kpfs",
      hidden: false,
      meta: {
        code: "RS-M13",
        title: "开票方式",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "paas-info/OrgPaasInfo",
    },
    {
      name: "SysMtgl",
      path: "middle-kind",
      component: "system/middle-kind/MiddleKind",
      hidden: false,
      meta: {
        code: "RS-M07",
        title: "中类管理",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
    },
  ],
};

// 日志管理
const logMenus = {
  name: "Log",
  path: "/log",
  hidden: false,
  redirect: "noRedirect",
  alwaysShow: true,
  meta: {
    code: "Logs",
    title: "系统日志",
    menu: true,
    keepAlive: true,
    // icon: require("@/assets/navMenu/system.png"),
    icon: "el-icon-date",
    link: null,
  },
  component: "Layout",
  children: [
    {
      name: "LogOprt",
      path: "oprt",
      hidden: false,
      meta: {
        code: "LOGS-OPER",
        title: "操作日志",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "logs-manage/oper",
    },
    {
      name: "LogAuth",
      path: "auth",
      hidden: false,
      meta: {
        code: "LOGS-LOGIN",
        title: "登录日志",
        menu: true,
        keepAlive: true,
        icon: null,
        link: null,
      },
      component: "logs-manage/login",
    },
  ],
};

export const allRoutes = [
  salMenus,
  colMenus,
  invMenus,
  chkMenus,
  cooSalMenus,
  cooBuyMenus,
  vouMenus,
  etyMenus,
  decMenus,
  sysMenus,
  logMenus,
];

// 基础路由
export const baseRoutes = [
  {
    path: "/",
    redirect: "/login",
    hidden: true,
  },
  {
    name: "Login",
    path: "/login",
    hidden: true,
    component: () => import("@/entry/pdt/Login"),
  },
  {
    name: "NotAuth",
    path: "/403",
    hidden: true,
    component: () => import("@/views/NotRoles"),
  },
  {
    name: "NotFound",
    path: "/404",
    hidden: true,
    component: () => import("@/views/NotFound"),
  },
  {
    path: "",
    component: Layout,
    redirect: "index",
    children: [
      {
        name: "Index",
        path: "index",
        component: () => import("@/views/index"),
        meta: {
          title: "系统首页",
          icon: "el-icon-place",
          affix: true,
        },
      },
    ],
  },
  {
    path: "/user",
    component: Layout,
    hidden: true,
    redirect: "noredirect",
    children: [
      {
        name: "Own",
        path: "own",
        component: () => import("@/views/personalCenter/PersonalCenter"),
        meta: { code: "SM", title: "个人中心", icon: "user" },
      },
    ],
  },
];
